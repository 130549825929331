import type { Optional } from '@/models/utils'
import { t } from '@/i18n'

export const EmptyGuid = '00000000-0000-0000-0000-000000000000'

export const replaceItemAt = <T>(arr: Array<T>, item: T, index: number): Array<T> => {
  return [...arr.slice(0, index), item, ...arr.slice(index + 1)]
}

export const parseAdvancedFloat = (val: Optional<string>) => {
  if (!val) return undefined
  return parseFloat(val)
}
export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const arrayToLimitedString = (arr: string[], limit: number) => {
  if(arr == null || arr.length === 0) return ""
  if (arr.length <= limit) return arr.join(', ')
  return `${arr.slice(0, limit).join(', ')}, ${t('general.plusWeitere', {count: arr.length - limit})}`
  }