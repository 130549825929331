<script lang="ts" setup>
import 'flag-icons/css/flag-icons.min.css'
import { onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useSettingsStore } from '@/stores/Settings/SettingsStore'
import { LanguagesWithCode } from '@/components/Settings/LanguagesWithCode'
import LicenceList from '@/components/Settings/LicenceList.vue'

const props = defineProps<{
  isVisible: boolean
}>()


const languagesWithCode = LanguagesWithCode
const { t } = useI18n()
const isVisibleRef = ref<boolean>(false)
const settingsStore = useSettingsStore()
const languages = ref<string[]>(settingsStore.settingsState.activeLanguages)
const licencesVisible = ref<boolean>(false)

watch(() => languages.value, (v, o) => {
  if (!v.find(x => x == 'de')) languages.value = o
})

watch(() => props.isVisible, (v) => isVisibleRef.value = v)
onMounted(async () => {
  await settingsStore.fetchSettings()
  languages.value = settingsStore.settingsState.activeLanguages
})

watch(isVisibleRef, (newVal) => {
  emit('update:isVisible', newVal)
})

const updateLanguages = async () => {
  if (languages.value.find(x => x == 'de'))
    await settingsStore.updateLanguages(languages.value)
}

const emit = defineEmits(['update:isVisible'])

</script>

<template>
  <Dialog
    v-model:visible="isVisibleRef"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    :header="t('general.Settings')"
    dismissable-mask
    modal
    style="width: 50vw; height: 80vh"
  >
    <Fieldset>
      <div class="form-with-label">
        <h4 class="self-center">
          {{ t('settings.Befundtextsprachen') }}
        </h4>
        <MultiSelect
          v-model="languages"
          :filter="false"
          :options="settingsStore.settingsState.allLanguages"
          class="w-full md:w-80"
          display="chip"
          option-label="name"
          @change="()=> updateLanguages()"
        >
          <template #option="slotProps">
            <div class="flex items-center">
              <span :class="`fi fi-${ slotProps.option}`" />
              <span style=" margin-left:  1rem">
                {{ 
                  languagesWithCode.find(x => x.code == slotProps.option)?.language
                }}
              </span>
            </div>
          </template>

          <template #chip="slotProps">
            <span :class="`fi fi-${ slotProps.value}`" />
          </template>
          <template #header>
            <div class="font-medium px-3 py-2">
              {{ t('settings.VerfügbareSprachen') }}
            </div>
          </template>
        </MultiSelect>
      </div>
    </Fieldset>
    <div class="p-dialog-title my-5">
      {{ t('settings.licences.header') }}
    </div>
    <LicenceList />
  </Dialog>
</template>

<style scoped>

</style>