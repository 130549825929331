import { OperandType } from '@/models/Enums/Operand'
import { t } from '@/i18n'
import type { RegelSubItemType } from '@/stores/regeln/models/RegelSubItemType'
import type { MreStufe } from '@/stores/regeln/models/MRE-Stufe'
import type { MibiValidationType } from '@/stores/regeln/models/Reinkultur'

// TODO: Maybe Refaactor
export const RegelPostionsType = {
  QUALITATIV: 'QUALITATIV',
  QUANTITATIV: 'QUANTITATIV',
  ENTHALTEN: 'ENTHALTEN',
  MIBI: 'MIBI',
  EMPTY: 'EMPTY'
} as const
const RegelPostionsTypeKeys = Object.keys(RegelPostionsType) as Array<
  keyof typeof RegelPostionsType
>

export function getRegelPostionsTypeIndex(
  type: (typeof RegelPostionsType)[keyof typeof RegelPostionsType]
): number {
  return RegelPostionsTypeKeys.indexOf(type as keyof typeof RegelPostionsType)
}

export const ZielStatus = {
  F: t('regeln.F'),
  B: t('regeln.B'),
  U: t('regeln.U'),
  E: t('regeln.noStatus')
} as const

export type ZielStatusType = (typeof ZielStatus)[keyof typeof ZielStatus]
const ZielStatusIndexMap = [
  t('regeln.F'),
  t('regeln.B'), // index 1
  t('regeln.U'), // index 2
  t('regeln.noStatus') // index 3
] as const

// Reverse mapping for finding the index by ZielStatus
const ZielStatusReverseMap: Record<ZielStatusType, number> = ZielStatusIndexMap.reduce(
  (acc, key, index) => {
    acc[key] = index
    return acc
  },
  {} as Record<ZielStatusType, number>
)

export function getZielStatusByIndex(index: number): ZielStatusType {
  return ZielStatusIndexMap[index]
}

export function getIndexByZielStatus(status: ZielStatusType): number {
  return ZielStatusReverseMap[status] ?? getIndexByZielStatus('EMPTY')
}

export const IncludeStatusValue = {
  IstEnthalten: t('dialogs.IstEnthalten'),
  IstNichtEnthalten: t('dialogs.NichtEnthalten')
} as const
export type IncludeStatusValueType = (typeof IncludeStatusValue)[keyof typeof IncludeStatusValue]

export const zielStatusKeys = Object.values(ZielStatus).filter((key) => isNaN(Number(key)))

// Define the interfaces and types for different value types
export interface Regelposition {
  id: string
  kuerzel: string
  subItemId: string
  subItemType: RegelSubItemType
  zielstatus: ZielStatusType
  regelSpezifikation?: RegelSpezifikation
}

export interface RegelpositionQuantitativ {
  type: typeof RegelPostionsType.QUANTITATIV
  value?: QuantitativValue
}

export interface RegelpositionQualitativ {
  type: typeof RegelPostionsType.QUALITATIV
  value?: Array<string>
  deltaCheckValues?: Array<string>
}

export interface RegelpositionEnthalten {
  type: typeof RegelPostionsType.ENTHALTEN
  value?: IncludeStatusValueType
}

export interface RegelpositionMibi {
  type: typeof RegelPostionsType.MIBI
  value?: MibiValue
}

export type RegelSpezifikation =
  | RegelpositionQuantitativ
  | RegelpositionQualitativ
  | RegelpositionEnthalten
  | RegelpositionMibi

export interface QuantitativValue {
  operand1: OperandType
  operand2?: OperandType | null
  wert1: number
  wert2?: number | null
  deltaCheck?: DeltaCheckValue
}

export interface DeltaCheckValue {
  id?: string
  operandLowerWert?: number
  operandUpperWert?: number
  upperWert?: number
  lowerWert?: number
  isUpperPercentile?: boolean
  isLowerPercentile?: boolean
  zeitMultiplier?: number
  zeitMultiplierEinheit?: number
  vorwertAltersgrenze?: number
  vorwertAltersgrenzeEinheit?: number
}

export interface MibiValue {
  mreStufe?: MreStufe
  resistenzMusterId?: string
  validationType?: MibiValidationType
}

export const createFormulaFromRegelposition = (pos: QuantitativValue): string => {
  let output = ''
  output += OperandType[pos.operand1]
  output += pos.wert1.toString()
  if (!pos.wert2 || !pos.operand2) return output
  output += ' '
  output += ' '
  output += OperandType[pos.operand2]
  output += pos.wert2.toString()
  return output
}
