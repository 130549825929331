<script lang="ts" setup>
import { useRoute } from 'vue-router'
import { onMounted, onUnmounted, ref } from 'vue'
import { t } from '@/i18n'
import '@fortawesome/fontawesome-free/css/all.css'
import { useFaecherAndFocusStore } from '@/stores/FaecherAndFocusStore'

const route = useRoute()
const routerLinkClass = (checkedRoute: string) => {
  if (route.path.toLowerCase() !== checkedRoute.toLowerCase()) return 'p-button p-button-text'
  return 'p-button'
}
const shortcutStore = useFaecherAndFocusStore()

const handleGlobalShortcuts = (event: KeyboardEvent) => {
  if (event.altKey && event.key === '+') {
    shortcutStore.handleAddEvent()
    event.preventDefault()
    return
  }
  if (event.key === 'Escape') {
    shortcutStore.handleEscEvent()
    return
  }

  if (event.ctrlKey && isArrowKey(event.key)) {
    event.preventDefault()
    if (isModalOpen()) return

    const faecherElement = document.getElementById('main-content')
    if (!faecherElement) return

    if (shortcutStore.cardWithFocus) {
      const activeCard = document.getElementById(shortcutStore.cardWithFocus)
      if (!activeCard) return

      const filteredCards = filterCardsByDirection(
        event.key,
        Array.from(faecherElement.getElementsByClassName('hus-card')),
        activeCard.getBoundingClientRect()
      )
      const best = getBestCard(filteredCards, activeCard.getBoundingClientRect(), event.key)

      if (best) {
        shortcutStore.changeCardFocus(best.id)
        ;(best as HTMLDivElement).focus()
      }
    }
  }
}

const isArrowKey = (key: string) =>
  ['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown'].includes(key)
const isModalOpen = () => Array.from(document.getElementsByClassName('p-dialog')).length > 0
const getBestCard = (
  filteredCards: Element[],
  activeRect: DOMRect,
  key: string
): Element | undefined => {
  return filteredCards.sort((a, b) => {
    const aRect = a.getBoundingClientRect()
    const bRect = b.getBoundingClientRect()

    if (key === 'ArrowRight' || key === 'ArrowLeft') {
      return Math.abs(aRect.y - activeRect.y) - Math.abs(bRect.y - activeRect.y)
    } else if (key === 'ArrowUp' || key === 'ArrowDown') {
      return Math.abs(aRect.y - activeRect.y) - Math.abs(bRect.y - activeRect.y)
    }
  })[0]
}
const filterCardsByDirection = (key: string, cards: Element[], activeRect: DOMRect): Element[] => {
  const filterByPosition = (
    xCondition: (x: number) => boolean,
    yCondition: (y: number) => boolean
  ) => {
    return cards.filter((card) => {
      const rect = card.getBoundingClientRect()
      return xCondition(rect.x) && yCondition(rect.y)
    })
  }

  let filteredCards: Element[] = []

  switch (key) {
    case 'ArrowRight':
      filteredCards = filterByPosition(
        (x) => x > activeRect.x,
        () => true
      )
      break

    case 'ArrowLeft':
      filteredCards = filterByPosition(
        (x) => x < activeRect.x,
        () => true
      )
      break

    case 'ArrowUp':
      filteredCards = filterByPosition(
        (x) => x === activeRect.x,
        (y) => y < activeRect.y && y > 0
      )

      if (!filteredCards.length && shortcutStore.index > 1) {
        window.removeEventListener('keydown', handleGlobalShortcuts)
        shortcutStore.changeIndex(shortcutStore.index - 1)
        setTimeout(() => window.addEventListener('keydown', handleGlobalShortcuts), 500)
      }
      break

    case 'ArrowDown':
      filteredCards = filterByPosition(
        (x) => x === activeRect.x,
        (y) => y > activeRect.y
      )

      if (!filteredCards.length) {
        filteredCards = filterByPosition(
          (x) => x <= activeRect.x,
          (y) => y > activeRect.y
        )
      }

      if (!filteredCards.length && shortcutStore.index < 3) {
        window.removeEventListener('keydown', handleGlobalShortcuts)
        shortcutStore.changeIndex(shortcutStore.index + 1)
        setTimeout(() => window.addEventListener('keydown', handleGlobalShortcuts), 500)
      }
      break
  }

  return filteredCards
}

onMounted(() => {
  window.addEventListener('keydown', handleGlobalShortcuts)
})

onUnmounted(() => {
  window.removeEventListener('keydown', handleGlobalShortcuts)
})

const formattedBuildTimestamp = ref(__BUILD_TIMESTAMP__)
</script>

<template>
  <div class="flex flex-col min-h-screen">
    <header class="flex justify-between px-8 py-4 h-16">
      <RouterLink
        tabindex="-1"
        to="/"
      >
        <img
          alt="Limbach Logo"
          src="@/assets/Limbach.png"
        >
      </RouterLink>
      <div class="ml-auto mr-4">
        Last build: {{ formattedBuildTimestamp }}
      </div>
      <ProfileMenu />
    </header>
    <div class="flex flex-grow">
      <nav class="w-[8.25rem] min-w-[6.25rem] flex flex-col items-center py-4">
        <RouterLink
          class="nav-item"
          to="/regelgruppen"
        >
          <Button
            :class="routerLinkClass('/regelgruppen')"
            :label="t('navigation.RegelAnlegen')"
            icon="fa-solid fa-rectangle-list"
            icon-pos="top"
          />
        </RouterLink>
        <hr class="custom-hr">

        <RouterLink
          class="nav-item"
          to="/analysen"
        >
          <Button
            :class="routerLinkClass('/analysen')"
            :label="t('navigation.Analysen')"
            icon="fa-solid fa-atom"
            icon-pos="top"
          />
        </RouterLink>
        <RouterLink
          class="nav-item"
          to="/gueltigkeiten"
        >
          <Button
            :class="routerLinkClass('/gueltigkeiten')"
            :label="t('navigation.Gueltigkeiten')"
            icon="fa-solid fa-house-medical-flag"
            icon-pos="top"
          />
        </RouterLink>
        <RouterLink
          class="nav-item"
          to="/kundengruppen"
        >
          <Button
            :class="routerLinkClass('/kundengruppen')"
            :label="t('navigation.Kundengruppen')"
            icon="fa-solid fa-hospital-user"
            icon-pos="top"
          />
        </RouterLink>
        <RouterLink
          class="nav-item"
          to="/probengueter"
        >
          <Button
            :class="routerLinkClass('/probengueter')"
            :label="t('navigation.Probengueter')"
            icon="fa-solid fa-vial"
            icon-pos="top"
          />
        </RouterLink>
        <RouterLink
          class="nav-item"
          to="/textbausteine"
        >
          <Button
            :class="routerLinkClass('/textbausteine')"
            :label="t('navigation.Textbausteine')"
            icon="fa-solid fa-comments"
            icon-pos="top"
          />
        </RouterLink>

        <RouterLink
          class="nav-item"
          to="/keime"
        >
          <Button
            :class="routerLinkClass('/keime')"
            :label="t('navigation.Keime')"
            icon="fa-solid fa-virus-covid"
            icon-pos="top"
          />
        </RouterLink>

        <RouterLink
          class="nav-item"
          to="/keimgruppen"
        >
          <Button
            :class="routerLinkClass('/keimgruppen')"
            :label="t('navigation.Keimgruppen')"
            icon="fa-solid fa-viruses"
            icon-pos="top"
          />
        </RouterLink>

        <RouterLink
          class="nav-item"
          to="/antibiotika"
        >
          <Button
            :class="routerLinkClass('/antibiotika')"
            :label="t('navigation.Antibiotika')"
            icon="fa-solid fa-prescription-bottle-medical"
            icon-pos="top"
          />
        </RouterLink>

        <RouterLink
          class="nav-item"
          to="/resistenzmuster"
        >
          <Button
            :class="routerLinkClass('/resistenzmuster')"
            :label="t('navigation.Resistenzmuster')"
            icon-pos="top"
          >
            <template #icon>
              <HusHealthIcon
                :size="1.8"
                icon-path="medicines"
                style="margin-bottom: -0.3rem"
              />
            </template>
          </Button>
        </RouterLink>
      </nav>
      <main
        id="main-content"
        class="content"
      >
        <slot />
      </main>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.nav-item {
  @apply m-4 flex justify-center items-center w-full;
  margin-top: 1px;
}

.nav-item > .p-button {
  @apply flex justify-center items-center;
  width: 100%;
  border-radius: 0;
  height: 77px;
}

.content {
  background: var(--background-gradient);
  border-color: var(--p-content-border-color, #dfe7ef);
  border-top-left-radius: var(--p-border-radius-xl);
  @apply flex-grow border-t border-l border-solid;
  overflow: hidden;
  position: relative;
}

Button {
  font-size: 1rem;
}

.custom-hr {
  border: none;
  height: 2px;
  background-color: #991355;
  margin: 5px 0 17px 0;
  width: 80%;
}

@media (max-height: 1100px) {
  .nav-item > .p-button {
    height: 64px;
  }
}

@media (max-height: 950px) {
  .nav-item > .p-button {
    height: 53px;
  }
  .nav-item {
    margin: 0.4rem;
  }
  Button {
    font-size: 0.9rem;
  }
}

@media (max-height: 790px) {
  .nav-item {
    margin: 0.2rem;
  }
  Button {
    font-size: 0.8rem;
  }
}
</style>
